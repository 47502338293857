import user from "../../assets/modulesUsers.png";
import sales from "../../assets/modulesSalesTracker.png";
import contracts from "../../assets/modulesContracts.png";
import academy from "../../assets/modulesAcademy.png";
import reporting from "../../assets/modulesReporting.png";
import time from "../../assets/modulesTime.png";
import messenger from "../../assets/modulesChat.png";
import settlement from "../../assets/modulesSettlement.png";

//GERMAN
export const featuresJson = [
  {
    id: "einleitung",
    title: "Einleitung",
    titleEn: "Introduction",
    subtitle:
      "Die Softwarelösung  ONERI wurde als komplette Suite zur Digitalisierung und Otimierung von Vertriebsprozessen designt. Mit einer großen Auswahl an Features und Funktionalitäten ermöglicht die ONERI-Suite, eine Verfolgung und Verwaltung aller Prozesse im Direktvertrieb in Echtzeit. Unter anderem ermöglicht ONERI eine mehrstufige Vergütungs- und Provisionsabrechnung auf Knopfdruck sowie eine sehr sehr effiziente und zeitnahe Vertriebssteuerung.",
    subtitleEn:
      "Delegate repetitive tasks to a software, let the human resources do more productive Higher-value works.",
    content:
      "Mit dem VERKAUFSTRACKER-Modul können Führungskräfte und Vertriebsmitarbeiter:innen alle Vertriebsaktivitäten einfach planen und überwachen, während die integrierte MESSENGER-App die interne Kommunikation zwischen allen Mitarbeiter:innen in Echtzeit ermöglicht, ohne auf externe Messengerdienste zugreifen zu müssen. Mit ONERI können Vertriebsteams auch in Regionen mit eingeschränkter Internetverbindung problemlos Neuverträge digital erfassen und vor Ort unterzeichnen lassen. Das AKADEMIE-Modul ermöglicht die Anlage und Zugriffsverwaltung zu Produkt- und Vertriebsschulungsunterlagen.",
    contentEn: "helps your team perform at their best!",
    image: null,
    options: [],
    content2:
      "ONERI ist die ultimative Software zur Optimierung und 100%igen Digitalisierung Ihrer Vertriebsprozesse. Sie ermöglicht eine Online-Zusammenarbeit über alle Vertriebsprozesse und führt bei konsequenter Einführung automatisch zu Transparenz und Umsatzwachstum im Unternehmen.",
    content2En:
      "The all-in-one sales optimization suite designed to help businesses digitalize and streamline their sales processes. With a wide range of modules, ONERI makes it effortless to track and manage sales activities, establish multi-level bonuses for your sales team, and promptly calculate and settle provisions. ONERI also empowers sales teams to work smarter. Our Sales tracker tool allows sales reps to plan their day with ease, while the integrated communication app keeps everyone in the loop and facilitates collaboration. With ONERI, sales teams can also easily compose and digitally sign contracts on the spot, even in areas with limited internet connectivity. And with the academy feature, ONERI provides sales teams with the tools and resources they need to stay on top of their game. Our comprehensive training materials help teams enhance their skills, stay up-to-date on industry trends, and continually improve their performance. In short, ONERI is the ultimate tool for businesses looking to optimize their sales processes, enhance collaboration, and drive revenue growth.",
    footer: "Bringt Ihre Vertriebsleistung auf das nächste Level!",
    footerEn: "",
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/einleitung" },
      {
        title: "Einleitung",
        titleEn: "Introduction",
        path: "/modules/einleitung",
        active: true,
      },
    ],
  },
  {
    id: "nutzer",
    title: "Nutzer:",
    titleEn: "Users:",
    subtitle:
      "ONERI ermöglicht die Anlage und Verwaltung aller App-Nutzer inklusiver aller zur Verwaltung und Abrechnung notwendigen Stammdaten. ONERI besitzt ein mehrstufiges Berechtigungskonzept. Jeder aktive Nutzer kann vom Innendienst jederzeit deaktiviert und zum späteren Zeitpunkt bei Bedarf wieder reaktiviert werden. Die NUTZER-Verwaltung erlaubt unterschiedliche Aktivitäten zur Bearbeitung der gesamten Nutzer-Liste:",
    subtitleEn:
      "The app allows users to view a list of all app users, deactivate or reactivate specific users, view current teams, and create user profiles for more detailed information. Users can also search for specific users by name.",
    options: [
      "Alle ONERI-Nutzer anzeigen",
      "Deaktivierung/Reaktivierung von bestimmten Nutzern zulassen",
      "Anzeigen und Erstellen von Teamstrukturen",
      "Benutzerprofile für detailliertere Stammdateninformationen",
      "Namenssuche nach bestimmten Benutzern",
    ],
    optionsEn: [
      "Display all app users",
      "Allow deactivation/reactivation of specific users",
      "View current teams and create",
      "User profiles for more detailed information",
      "Search for specific users by name",
    ],
    options2: [],
    options3: [
      "Mitarbeiter:in Innendienst (Angestellte Backoffice)",
      "Management (Vertriebs- und Kennzahlenreporting)",
    ],
    options3En: [],
    options4: [
      "Mitarbeiter:in Vertrieb (Angestellte Vertrieb), ",
      "Mitarbeiter:in Agentur (angebundene Agenturen und freie Mitarbeiter)",
    ],
    options4En: [],
    options5: [
      "Mitarbeiter:in (Angestellter) ",
      "Teamleiter-Mitarbeiter (angestellter Teamleiter)",
      "Ausbilder-Mitarbeiter (angestellter Teiteamleiter)",
      "Mitarbeiter:in Agentur (freier/angebundener Mitarbeiter)",
      "Teamleiter-Agentur (angebundene Agentur)",
    ],
    options5En: [],
    options6: ["Parkend", "Genehmigt", "Geliefert"],
    options6En: [],

    content: "",
    contentEn: "",
    content2:
      "Nutzer erstellen: ONERI ermöglicht die Anlage von neuen Nutzern nach vier verschiedenen Berechtigungskonzepten bzw. Nutzerrollen, zum Beispiel:",
    content2En:
      "The app allows users to create four types of user roles: Employee, SP (Sales Person), Agency, and Agency SP. A form is provided to fill out with general, ID, and financial information for each user. For companies, basic company information can also be provided. All user information is accessible through the user profileCreate four types of user roles: Employee, SP (SalesPerson), Agency, and Agency SP Fill out a form with General Information, ID, and Financial Information For companies, provide basic company informtion User information is accessible through the User Profile",
    content3: "Nutzerrollen, zum Beispiel:",
    content3En: "User Profile:",
    content4: "1. Über die WEB-Anwendung:",
    content4En:
      "The user can view initial information such as personal and bank information, access uploaded files (if applicable), and view a list of orders they have created. Orders are grouped into Pending, Approved, and Delivered categories. The Paid Orders page can be used to 'settle' orders and immediately create calculations for them View initial information such as Personal Information and Bank Information",
    content5: "2. Über die Mobile-App-Anwendung:",
    content5En: "",
    content6:
      "Für jeden Nutzer steht ein Formular für alle notwendigen Stammdaten zur Verfügung. Für angebundene Unternehmen bzw. Agenturen können Unternehmensstammdaten hinterlegt werden. Auf die Stammdateninformationen aller Mitarbeiter und angebundenen Unternehmen/Agenturen kann durch das hinterlegte Zugangs- und Berechtigungskonzept ausschließlich über die Nutzerrolle „Backoffice“ zugegriffen werden. Erstellen Sie unterschiedliche Arten von Benutzerrollen für den Vertrieb (Frontoffice):",
    content6En: "",
    content7:
      "Füllen Sie ein Formular mit vertriebs- und abrechnungsrelevanten Stammdaten aus. Für Unternehmen geben Sie die grundlegenden Unternehmensstammdaten an. Die Nutzerinformationen sind nur für berechtigte Mitarbeiter über das Nutzerprofil zugänglich.",
    content7En: "",
    content8:
      "Nutzer Vertrieb (Frontoffice): Der Vertriebsmitarbeiter:innen nutzt ONERI über die Mobile-App. Er kann eigene Stammdaten einsehen und auf von ihm selbst hochgeladene Dokumente (falls zutreffend) zugreifen. Er hat Zugriff auf alle von ihm erstellten Aufträge. Die Aufträge sind mit dem jeweils aktuellen Bearbeitungsstatus",
    content8En: "",
    content9:
      "gekennzeichnet. Auf der Seite bezahlte Aufträge können abgerechnete Aufträge verglichen und heruntergeladen werden.",
    content9En: "",
    content10:
      "Hier können Sie Stammdaten und persönlich hinterlegte Dokumente einsehen.",
    content10En: "",
    image: user,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/nutzer" },
      {
        title: "Nutzer",
        titleEn: "Users",
        path: "/modules/nutzer",
        active: true,
      },
    ],
  },
  {
    id: "academy",
    title: "Academy:",
    titleEn: "Academy:",
    subtitle:
      "Das AKADEMIE-Modul ermöglicht es, Schulungsunterlagen und Präsentationen für andere Nutzer zu erstellen, zu bearbeiten und zu hinterlegen. Dazu gehört auch die Möglichkeit, neue Schulungskategorien zu erstellen sowie Entwürfe von Schulungen zu speichern, welche nur für den Urheber sichtbar sind, bevor die Schulung freigegeben und veröffentlicht wird. Auf diese Weise können Nutzende Schulungsinhalte für andere Nutzende erstellen und organisieren:",
    subtitleEn:
      "The user role for training allows the user to create and edit training for other user roles on the Training page. This includes the ability to create new categories for training using the 'Create Category' button, as well as save drafts of training that are only visible to the tenant before sharing and publishing the training. This allows the user to create and organize training content for other users in the system`",
    options: [
      "Erstellen und Bearbeiten von Schulungen für andere Nutzerrollen",
      "Neue Kategorien für Schulungen erstellen",
      "Entwürfe von Schulungen speichern, die nur für den Urheber sichtbar sind, bevor sie freigegeben und veröffentlicht werden",
      "Benutzerinformationen sind über das Benutzerprofil zugänglich",
    ],
    optionsEn: [
      "Create and edit training for other user roles on the Training page",
      "Create and edit training for other user roles on the Training page",
      "Create new categories for training using the 'Create Category' button",
      "Save drafts of training, visible only to the tenant, before sharing and publishing",
    ],
    content: "",
    contentEn: "",
    image: academy,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/academy" },
      {
        title: "Akademie",
        titleEn: "Academy",
        path: "/modules/academy",
        active: true,
      },
    ],
  },
  {
    id: "time-tracker",
    title: "Zeittracker",
    titleEn: "Time Tracker",
    subtitle:
      "Die Einchecken/Auschecken-Funktionen im ONERI ermöglicht eine Erfassung und Verfolgung von Arbeits- und Pausenzeiten in Echtzeit. Mit nur wenigen Klicks können Nutzer ihre Arbeitszeiten registrieren und entsprechende Pausenzeiten auswählen. ONERI ermöglicht das Reporting zu arbeitszeitbezogenen Leistungskennzahlen. Es können automatische Benachrichtigungen zu Arbeits- und Pausenzeiten hinterlegt werden: ",
    subtitleEn:
      "The check-in/check-out functionality of our mobile and web app allows users to easily track their work hours and breaks. With just a few taps or clicks, users can clock in and out of their shifts and select any breaks taken throughout the day. The app also offers the ability to view reports and performance metrics, allowing both employees and managers to track productivity and identify areas for improvement. Additionally, users can set reminders and notifications to ensure they never forget to check in or out.",
    options: [
      "Einchecken/Auschecken-Funktionen zur Erfassung von Arbeitszeiten und Pausen",
      "Arbeitszeiten einfach erfassen, Pausen auswählen",
      "Leistungskennzahlen und Berichte zur Verfolgung arbeitszeitrelevanter Kriterien",
      "Erinnerungen und Benachrichtigungen zum aktiven Ein- und Auschecken",
    ],
    optionsEn: [
      "Mobile and web app offer check-in/check-out functionality for tracking work hours and breaks",
      "Users can easily clock in and out of their shifts and select any breaks taken throughout the day",
      "App provides performance metrics and reports for tracking productivity and identifying areas for improvement",
      "Reminders and notifications help users stay on track with checking in and out",
      "Check-in/check-out functionality streamlines the process of tracking work hours and breaks, providing valuable insights for both employees and managers.",
    ],
    content: "",
    contentEn: "",
    image: time,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/time-tracker" },
      {
        title: "Zeittracker",
        titleEn: "Time Tracker",
        path: "/modules/time-tracker",
        active: true,
      },
    ],
  },
  {
    id: "sales-tracker",
    title: "Verkaufstracker:",
    titleEn: "Sales Tracker:",
    subtitle:
      "Das VERKAUFSTRACKER-Modul wurde für ein aktive Vertriebssteuerung und ein effizientes Management von Kunden- und Leadlisten entwickelt. Die wichtigsten Funktionen u.a.:",
    subtitleEn:
      "The Sales Tracker module in oneri application is designed to help sales teams manage their leads efficiently. Here are the key features:",
    options: [
      "Import von Leadlisten",
      "Verfolgung des Lead-Bearbeitungsstatus",
      "Zuweisung von Leads an Mitarbeiter:innen",
      "Ansicht der Lead-Historie",
      "Zeitliche und geographische Verfolgung der Vertriebsperformance in Projekten",
    ],
    optionsEn: [
      "Import leads in bulk",
      "Track lead status",
      "Assign leads to employees",
      "View lead history",
      "Employee access control based on location",
    ],
    content:
      "Das VERKAUFSTRACKER-Modul erleichtert die Verwaltung von Leads und die Priorisierung von Vertriebsaktivitäten. Er ermöglicht eine pipelinebasierte Zuweisung und Abarbeitung von Leadpotentialen. Eine standortbasierte Zugriffskontrolle für Mitarbeiter:innen gewährleistet die Integrität der Lead-Daten.",
    contentEn:
      "The Sales Tracker module makes it easier to manage leads and prioritize sales efforts. The module allows sales teams to see which leads are in the pipeline, assign leads to specific employees, and track all interactions with each lead. The location-based employee access control feature ensures the integrity of lead data.",
    content2:
      "Der VERKAUFSTRACKER ermöglicht die individuelle Verfolgung von Vertriebsaktivitäten, inklusive",
    content2En: "",

    options2: [
      "Erfolgsquote",
      "Besuchsfrequenz",
      "Geographische Lage der Kunden",
    ],
    options2En: [],
    image: sales,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/sales-tracker" },
      {
        title: "Verkaufstracker",
        titleEn: "Sales Tracker",
        path: "/modules/sales-tracker",
        active: true,
      },
    ],
  },
  {
    id: "verträge",
    title: "Verträge",
    titleEn: "Contracts",
    subtitle:
      "Das VERTRÄGE-Modul ermöglicht die Übersicht, Verwaltung und Einzelsuche  aller eingereichten  Aufträge. Der Nutzer kann Aufträge nach Datum bzw. Zeitfenster filtern oder neue Aufträge erstellen:",
    subtitleEn:
      "Orders allow the user to view all previous orders on the Orders page, as well as search for specific orders using the search bar. The user can also filter orders by date, and create new orders using the plus (+) icon",
    options: [
      "Anzeige aller eingereichten Aufträge",
      "Nach bestimmten Aufträgen suchen",
      "Aufträge nach Zeitfenster filtern",
      "Neue Aufträge erstellen und erfassen",
    ],
    optionsEn: [
      "View all previous orders on the Orders page",
      "Search for specific orders using the search bar",
      "Filter orders by date",
      "Create new orders using the plus (+) icon contract",
    ],
    content:
      "Vertrag – Auftrag erstellen: Beim Anlegen eines neuen Auftrags kann der Nutzer einen Produktgeber für den Vertrag auswählen. Anschließend wird ihm ein spezifisches Auftragsformular zum Ausfüllen beim Kunden zur Verfügung gestellt. Im Anforderungsfalls kann der Kunde auf dem Tablet bzw. Smart-Phone den Neuauftrag unterschreiben. Neu erstellte Aufträge können anschließend per Knopfdruck zur Weiterbearbeitung im Innendienst eingereicht werden.",
    contentEn:
      "Creating contract: Creating Order allows the user to choose a client for the order and fill out a form with basic information about the order and the client. The user can then submit the order, which will be marked as 'Pending' for the tenant to view",
    content2:
      "Erfassen Sie alle auftragsrelevanten Daten des Neukunden und zum bestellten Produkt bzw. Tarif.",
    content2En:
      "Choose a client for the order Fill out a form with basic information about the order and the client",
    content3:
      "Aufträge unterschreiben: Mit der Funktion Vertrag unterschreiben kann der Vertrieb bei Bedarf erforderliche Kundenunterschriften für Neuaufträge, Lastschriftmandate u.a. einholen. Die Unterschriften werden auf dem Tablet oder Smart-Phone geleistet und von ONERI automatisch in den neu generierten Auftrag übernommen. Die Auftragserfassung- und Verwaltung ist somit 100% papierlos.",
    content3En: "Contracts Sign",
    content4:
      "Erfassen Sie bei Bedarf digitale Kundenunterschriften für Neuaufträge und Zusatzvereinbarungen. Die Unterschriften werden in die von ONERI generierten endgültigen Vertragsdokumente automatisch übernommen.",
    content4En:
      "Contract Sign allows the user to get customer signatures on sections of the contract as needed. These signatures are included in the final contract files generated by Oneri. This allows the user to easily manage and track signatures on contracts within the system. Get customer signatures on sections of the contract as needed  ",
    image: contracts,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/verträge" },
      {
        title: "Verträge",
        titleEn: "Contracts",
        path: "/modules/verträge",
        active: true,
      },
    ],
  },
  {
    id: "messenger",
    title: "Messenger",
    titleEn: "Chat",
    subtitle:
      "Der MESSENGER ermöglicht eine direkte Kommunikation innerhalb ONERI zwischen und zu allen Nutzern. Es besteht die Möglichkeit der direkten 1:1-Kommunikation und innerhalb von Gruppen:",
    subtitleEn:
      "Messages allow the user to communicate with other users on the Messages page. This includes the ability to chat privately with 'One on One' messaging, as well ascreate teams and communication groups. This allows the user to communicate with other users in the system in a variety of ways, including individual and group chats.",
    options: [
      "Senden und erhalten Sie Nachrichten an/von Teammitgliedern",
      "Privater Chat mit 1:1-Nachrichten zwischen einzelnen Nutzern",
      "Erstellen Sie Chatgruppen und Threads zu unterschiedlichen Themen",
      "Versenden Sie regelmäßige Newsletter an alle oder ausgewählte Nutzergruppen",
    ],
    optionsEn: [
      "Communicate with other users on the Messages page",
      "Chat privately with 'One on One' messaging",
      "Create teams and communication groups",
    ],
    content: "",
    contentEn: "",
    image: messenger,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/messenger" },
      {
        title: "Messenger",
        titleEn: "Chat",
        path: "/modules/messenger",
        active: true,
      },
    ],
  },
  {
    id: "abrechnung",
    title: "Abrechnung",
    titleEn: "Settlement",
    subtitle:
      "Das ABRECHNUNGS-Modul ermöglicht  die Auflistung und Anzeige bereits abgerechneter und noch abzurechnender Aufträge. Die Nutzer können ihr Auftragsportfolio nach Status  „Bezahlt“ und „Genehmigt“ sowie nach beliebigen Zeitfenstern filtern:",
    subtitleEn:
      "The user role for Calculations allows the user to view Settled orders on the Calculations page. The user can filter these calculations by the Paid and Approved tabs, as well as by date using the calendar. This allows the user to view and track the status of settled orders and see the calculation breakdown for each order.",
    options: [
      "Abgerechnete Aufträge auf der Seite Berechnungen anzeigen",
      "Filtern der Berechnungen nach „Bezahlt“ und „Genehmigt“ (zur Auszahlung freigegeben)",
      "Filtern nach Zeiträumen ",
    ],
    optionsEn: [
      "View Settled orders on the Calculations page",
      "Filter calculations by Paid and Approved tabs",
      "Filter by date using the calendar",
    ],
    content: "",
    contentEn: "",
    image: settlement,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/abrechnung" },
      {
        title: "Abrechnung",
        titleEn: "Settlement",
        path: "/modules/abrechnung",
        active: true,
      },
    ],
  },
  {
    id: "reporting",
    title: "Reporting",
    titleEn: "Reporting",
    subtitle:
      "Das Dashboard stellt allen Nutzern je nach Zugriffsrecht, Statistiken und Kennzahlen für ausstehende und abgewickelte Aufträge auf der Dashboard-Seite in Echtzeit zur Verfügung. Ein Dashboard gibt es sowohl in der Mobile-App (Frontoffice) als auch in der WEB-Anwendung (Backoffice). Die Nutzer können vorhandene Daten nach Zeitraum filtern und Daten im Zeitverlauf vergleichen. Dadurch haben Nutzer Zugriff auf einen Überblick in Echtzeit über Umsatz, Einkommen und Vertriebsperformance:",
    subtitleEn:
      "The dashboard provides real-time statistics and metrics for pending and processed orders to all users based on their access rights on the dashboard page. There is a dashboard in both the mobile app (front office) and the web application (back office). Users can filter existing data by time period and compare data over time. This gives users access to a real-time overview of sales, income and sales performance:",
    options: [
      "Anzeigen von Statistiken und Kennzahlen für ausstehende und abgewickelte Aufträge",
      "Filterfenster in jeder Zeit",
    ],
    optionsEn: [
      "View statistics and metrics for pending and completed orders",
      "Filter window by any time",
    ],
    content: "",
    contentEn: "",
    image: reporting,
    paths: [
      { title: "Module", titleEn: "Modules", path: "/modules/reporting" },
      {
        title: "Reporting",
        titleEn: "Reporting",
        path: "/modules/reporting",
        active: true,
      },
    ],
  },
];

export const featuresMainJson = {
  id: "einleitung",
  title: "Einleitung",
  subtitle:
    "Die Softwarelösung  ONERI wurde als komplette Suite zur Digitalisierung und Otimierung von Vertriebsprozessen designt. Mit einer großen Auswahl an Features und Funktionalitäten ermöglicht die ONERI-Suite, eine Verfolgung und Verwaltung aller Prozesse im Direktvertrieb in Echtzeit. Unter anderem ermöglicht ONERI eine mehrstufige Vergütungs- und Provisionsabrechnung auf Knopfdruck sowie eine sehr sehr effiziente und zeitnahe Vertriebssteuerung.",
  content:
    "Mit dem Modul Verkaufstracker können Führungskräfte und Vertriebsmitarbeiter:innen alle Vertriebsaktivitäten einfach planen und überwachen, während die integrierte MESSENGER-App die interne Kommunikation zwischen allen Mitarbeiter:innen in Echtzeit ermöglicht, ohne auf externe Messengerdienste zugreifen zu müssen. Mit ONERI können Vertriebsteams auch in Regionen mit eingeschränkter Internetverbindung problemlos Neuverträge digital erfassen und vor Ort unterzeichnen lassen. Das ACADEMY-Modul ermöglicht die Anlage und Zugriffsverwaltung zu Produkt- und Vertriebsschulungsunterlagen.",
  image: null,
  options: [],
  content2:
    "ONERI ist die ultimative Software zur Optimierung und 100%igen Digitalisierung Ihrer Vertriebsprozesse. Sie ermöglicht eine Online-Zusammenarbeit über alle Vertriebsprozesse und führt bei konsequenter Einführung automatisch zu Transparenz und Umsatzwachstum im Unternehmen.",
  footer: "bringt Ihre Vertriebsleistung auf das nächste Level!",
  paths: [{ title: "Einleitung", path: "/modules", active: true }],
};

export const featureItems = [
  {
    name: "Einleitung",
    link: "einleitung",
    nameEn: "Introduction",
    linkEn: "introduction",
    option: [],
  },
  {
    name: "Nutzer",
    link: "nutzer",
    nameEn: "User",
    linkEn: "user",
    option: [],
  },
  {
    name: "Akademie",
    link: "academy",
    nameEn: "Academy",
    linkEn: "academy",
    option: [],
  },
  {
    name: "Zeittracker",
    link: "time-tracker",
    nameEn: "Time Tracker",
    linkEn: "time-tracker",
    option: [],
  },
  {
    name: "Verkaufstracker",
    link: "sales-tracker",
    nameEn: "Sales Tracker",
    linkEn: "sales-tracker",
    option: [],
  },
  {
    name: "Verträge",
    link: "verträge",
    nameEn: "Contracts",
    linkEn: "contracts",
    option: [],
  },
  {
    name: "Messenger",
    link: "messenger",
    nameEn: "Chat",
    linkEn: "messenger",
    option: [],
  },
  {
    name: "Abrechnung",
    link: "abrechnung",
    nameEn: "Settlement",
    linkEn: "settlement",
    option: [],
  },
  {
    name: "Reporting",
    link: "reporting",
    nameEn: "Reporting",
    linkEn: "reporting",
    option: [],
  },
];

//ENGLISH
export const featuresJsonEn = [
  {
    id: "einleitung",
    titleEn: "Introduction",
    subtitleEn:
      "Delegate repetitive tasks to a software, let the human resources do more productive Higher-value works.",
    contentEn: "helps your team perform at their best!",
    image: null,
    options: [],
    content2En:
      "The all-in-one sales optimization suite designed to help businesses digitalize and streamline their sales processes. With a wide range of modules, ONERI makes it effortless to track and manage sales activities, establish multi-level bonuses for your sales team, and promptly calculate and settle provisions. ONERI also empowers sales teams to work smarter. Our Sales tracker tool allows sales reps to plan their day with ease, while the integrated communication app keeps everyone in the loop and facilitates collaboration. With ONERI, sales teams can also easily compose and digitally sign contracts on the spot, even in areas with limited internet connectivity. And with the academy feature, ONERI provides sales teams with the tools and resources they need to stay on top of their game. Our comprehensive training materials help teams enhance their skills, stay up-to-date on industry trends, and continually improve their performance. In short, ONERI is the ultimate tool for businesses looking to optimize their sales processes, enhance collaboration, and drive revenue growth.",
    footerEn: "",
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/einleitung" },
      { title: "Einleitung", path: "/modules/einleitung", active: true },
    ],
  },
  {
    id: "users",
    titleEn: "Users:",
    subtitleEn:
      "The app allows users to view a list of all app users, deactivate or reactivate specific users, view current teams, and create user profiles for more detailed information. Users can also search for specific users by name.",
    optionsEn: [
      "Display all app users",
      "Allow deactivation/reactivation of specific users",
      "View current teams and create",
      "User profiles for more detailed information",
      "Search for specific users by name",
    ],
    options2: [],
    // options3: [
    //   "Mitarbeiter:in Innendienst (Angestellte Backoffice)",
    //   "Management (Vertriebs- und Kennzahlenreporting)",
    // ],
    // options4: [
    //   "Mitarbeiter:in Vertrieb (Angestellte Vertrieb), ",
    //   "Mitarbeiter:in Agentur (angebundene Agenturen und freie Mitarbeiter)",
    // ],
    // options5: [
    //   "Mitarbeiter:in (Angestellter) ",
    //   "Teamleiter-Mitarbeiter (angestellter Teamleiter)",
    //   "Ausbilder-Mitarbeiter (angestellter Teiteamleiter)",
    //   "Mitarbeiter:in Agentur (freier/angebundener Mitarbeiter)",
    //   "Teamleiter-Agentur (angebundene Agentur)",
    // ],
    // options6: ["Parkend", "Genehmigt", "Geliefert"],

    // content: "",
    content2En:
      "The app allows users to create four types of user roles: Employee, SP (Sales Person), Agency, and Agency SP. A form is provided to fill out with general, ID, and financial information for each user. For companies, basic company information can also be provided. All user information is accessible through the user profileCreate four types of user roles: Employee, SP (SalesPerson), Agency, and Agency SP Fill out a form with General Information, ID, and Financial Information For companies, provide basic company informtion User information is accessible through the User Profile",
    content3En: "User Profile:",
    content4En:
      "The user can view initial information such as personal and bank information, access uploaded files (if applicable), and view a list of orders they have created. Orders are grouped into Pending, Approved, and Delivered categories. The Paid Orders page can be used to 'settle' orders and immediately create calculations for them View initial information such as Personal Information and Bank Information",
    // content5: "2. über die Mobil-App-Anwendung:",
    // content6:
    //   "Für jeden Nutzer steht ein Formular für alle notwendigen Stammdaten zur Verfügung. Für angebundene Unternehmen bzw. Agenturen können Unternehmensstammdaten hinterlegt werden. Auf die Stammdateninformationen aller Mitarbeiter und angebundenen Unternehmen/Agenturen kann durch das hinterlegte Zugangs- und Berechtigungskonzept ausschließlich über die Nutzerrolle „Backoffice“ zugegriffen werden. Erstellen Sie unterschiedliche Arten von Benutzerrollen für den Vertrieb (Frontoffice):",
    // content7:
    //   "Füllen Sie ein Formular mit vertriebs- und abrechnungsrelevanten Stammdaten aus. Für Unternehmen geben Sie die grundlegenden Unternehmensstammdaten an. Die Nutzerinformationen sind nur für berechtigte Mitarbeiter über das Nutzerprofil zugänglich.",
    // content8:
    //   "Nutzer Vertrieb (Frontoffice): Der Vertriebsmitarbeiter nutzt ONERI über die Mobil-APP. Er kann eigene Stammdaten einsehen und auf von ihm selbst hochgeladene Dokumente (falls zutreffend) zugreifen. Er hat Zugriff auf alle von ihm erstellten Aufträge. Die Aufträge sind mit dem jeweils aktuellen Bearbeitungsstatus",
    // content9:
    //   "gekennzeichnet. Auf der Seite bezahlte Aufträge können abgerechnete Aufträge verglichen und runtergeladen werden.",
    // content10:
    //   "Hier können Sie Stammdaten und persönlich hinterlegte Dokumente einsehen.",
    image: user,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/nutzer" },
      { title: "Nutzer", path: "/modules/nutzer", active: true },
    ],
  },
  {
    id: "academy",
    titleEn: "Academy:",
    subtitleEn:
      "The user role for training allows the user to create and edit training for other user roles on the Training page. This includes the ability to create new categories for training using the 'Create Category' button, as well as save drafts of training that are only visible to the tenant before sharing and publishing the training. This allows the user to create and organize training content for other users in the system`",
    optionsEn: [
      "Create and edit training for other user roles on the Training page",
      "Create and edit training for other user roles on the Training page",
      "Create new categories for training using the 'Create Category' button",
      "Save drafts of training, visible only to the tenant, before sharing and publishing",
    ],
    content: "",
    image: academy,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/academy" },
      { title: "Academy", path: "/modules/academy", active: true },
    ],
  },
  {
    id: "time-tracker",
    titleEn: "Time Tracker",
    subtitleEn:
      "The check-in/check-out functionality of our mobile and web app allows users to easily track their work hours and breaks. With just a few taps or clicks, users can clock in and out of their shifts and select any breaks taken throughout the day. The app also offers the ability to view reports and performance metrics, allowing both employees and managers to track productivity and identify areas for improvement. Additionally, users can set reminders and notifications to ensure they never forget to check in or out.",
    optionsEn: [
      "Mobile and web app offer check-in/check-out functionality for tracking work hours and breaks",
      "Users can easily clock in and out of their shifts and select any breaks taken throughout the day",
      "App provides performance metrics and reports for tracking productivity and identifying areas for improvement",
      "Reminders and notifications help users stay on track with checking in and out",
      "Check-in/check-out functionality streamlines the process of tracking work hours and breaks, providing valuable insights for both employees and managers.",
    ],
    // content: "",
    image: time,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/time-tracker" },
      { title: "Time Tracker", path: "/modules/time-tracker", active: true },
    ],
  },
  {
    id: "sales-tracker",
    titleEn: "Sales Tracker:",
    subtitleEn:
      "The Sales Tracker module in oneri application is designed to help sales teams manage their leads efficiently. Here are the key features:",
    optionsEn: [
      "Import leads in bulk",
      "Track lead status",
      "Assign leads to employees",
      "View lead history",
      "Employee access control based on location",
    ],
    contentEn:
      "The Sales Tracker module makes it easier to manage leads and prioritize sales efforts. The module allows sales teams to see which leads are in the pipeline, assign leads to specific employees, and track all interactions with each lead. The location-based employee access control feature ensures the integrity of lead data.",
    // content2:
    //   "Der SALES TRACKER ermöglicht die individuelle Verfolgung von Vertriebsaktivitäten, inklusive",

    // options2: [],
    image: sales,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/sales-tracker" },
      { title: "Sales Tracker", path: "/modules/sales-tracker", active: true },
    ],
  },
  {
    id: "contracts",
    titleEn: "Contracts",
    subtitleEn:
      "Orders allow the user to view all previous orders on the Orders page, as well as search for specific orders using the search bar. The user can also filter orders by date, and create new orders using the plus (+) icon",
    optionsEn: [
      "View all previous orders on the Orders page",
      "Search for specific orders using the search bar",
      "Filter orders by date",
      "Create new orders using the plus (+) icon contract",
    ],
    contentEn:
      "Creating contract: Creating Order allows the user to choose a client for the order and fill out a form with basic information about the order and the client. The user can then submit the order, which will be marked as 'Pending' for the tenant to view",
    content2En:
      "Choose a client for the order Fill out a form with basic information about the order and the client",
    content3En: "Contracts Sign",
    content4En:
      "Contract Sign allows the user to get customer signatures on sections of the contract as needed. These signatures are included in the final contract files generated by Oneri. This allows the user to easily manage and track signatures on contracts within the system. Get customer signatures on sections of the contract as needed  ",
    content5En:
      "Signatures are included in the final contract files generated by Oneri",
    image: contracts,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/verträge" },
      { title: "Verträge", path: "/modules/verträge", active: true },
    ],
  },
  {
    id: "chat",
    titleEn: "Chat",
    subtitleEn:
      "Messages allow the user to communicate with other users on the Messages page. This includes the ability to chat privately with 'One on One' messaging, as well ascreate teams and communication groups. This allows the user to communicate with other users in the system in a variety of ways, including individual and group chats.",
    optionsEn: [
      "Communicate with other users on the Messages page",
      "Chat privately with 'One on One' messaging",
      "Create teams and communication groups",
    ],
    // content: "",
    image: messenger,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/messenger" },
      { title: "Messenger", path: "/modules/messenger", active: true },
    ],
  },
  {
    id: "abrechnung",
    titleEn: "Settlement",
    subtitle:
      "The user role for Calculations allows the user to view Settled orders on the Calculations page. The user can filter these calculations by the Paid and Approved tabs, as well as by date using the calendar. This allows the user to view and track the status of settled orders and see the calculation breakdown for each order.",
    optionsEn: [
      "View Settled orders on the Calculations page",
      "Filter calculations by Paid and Approved tabs",
      "Filter by date using the calendar",
    ],
    // content: "",
    image: settlement,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/abrechnung" },
      { title: "Abrechnung", path: "/modules/abrechnung", active: true },
    ],
  },
  {
    id: "reporting",
    titleEn: "Reporting",
    subtitleEn:
      "The dashboard provides real-time statistics and metrics for pending and processed orders to all users based on their access rights on the dashboard page. There is a dashboard in both the mobile app (front office) and the web application (back office). Users can filter existing data by time period and compare data over time. This gives users access to a real-time overview of sales, income and sales performance:",
    optionsEn: [
      "View statistics and metrics for pending and completed orders",
      "Filter window by any time",
    ],
    content: "",
    image: reporting,
    paths: [
      { title: "Modules", titleEn: "Modules", path: "/modules/reporting" },
      { title: "Reporting", path: "/modules/reporting", active: true },
    ],
  },
];

export const featureItemsEn = [
  {
    nameEn: "Introduction",
    linkEn: "introduction",
    option: [],
  },
  {
    nameEn: "User",
    linkEn: "user",
    option: [],
  },
  {
    nameEn: "Academy",
    linkEn: "academy",
    option: [],
  },
  {
    nameEn: "Time Tracker",
    linkEn: "time-tracker",
    option: [],
  },
  {
    nameEn: "Sales Tracker",
    linkEn: "sales-tracker",
    option: [],
  },
  {
    nameEn: "Contracts",
    linkEn: "contracts",
    option: [],
  },
  {
    nameEn: "Chat",
    linkEn: "messenger",
    option: [],
  },
  {
    nameEn: "Settlement",
    linkEn: "settlement",
    option: [],
  },
  {
    nameEn: "Reporting",
    linkEn: "reporting",
    option: [],
  },
];
