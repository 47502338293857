import React, { useEffect, useRef, useState } from "react";
import "./WebFeatures.css";
import dashboard from "../../assets/dashboard.svg";
import frontOfficeChart from "../../assets/backChart.svg";
import frontOfficeChartEn from "../../assets/backChartEn.svg";
import tick from "../../assets/tick.svg";

const WebPlatform = ({ isEnglish }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const expandableTextClass = isExpanded
    ? "expandable-text expandable-text-lin7 show-more"
    : "expandable-text expandable-text-lin7";
  return (
    <div className="section__two--content">
      <div className={`section__two--text ${expandableTextClass}`}>
        <span className="section__two--text-h1">Back Office</span>
        <br />
        <br />
        {isEnglish ? (
          <span className="section__two--text-p">
            ONERI empowers your sales back office to effortlessly manage and
            oversee sales operations in real-time. With our advanced features,
            you can monitor the daily activities of every sales team member and
            track the progress of each signed contract, easily moving them
            through different stages based on review and status. By utilizing
            ONERI, you can streamline the approval process and easily manage
            contract status, making it simple to view and control every signed
            contract from your sales team.
            <br />
            <br />
            This gives you complete oversight and control over your sales
            operations, helping to ensure that everything is in order
            Furthermore, with our settlement module, ONERI automatically
            generates and distributes monthly payslips for each sales team
            member after contract approval. This helps you reduce your
            operational time and increases efficiency, enabling your sales team
            to focus on what they do best - closing deals and driving revenue
            growth.In conclusion, ONERI is the perfect solution for businesses
            looking to optimize their sales processes and drive productivity and
            take your sales operations to the next level!
          </span>
        ) : (
          <span className="section__two--text-p">
            ONERI ermöglicht Ihrem Vertriebs-Innendienst die mühelose Verwaltung
            und Überwachung von Vertriebsaktivitäten in Echtzeit. Mit der
            Vielzahl an Funktionalitäten können Sie die täglichen Aktivitäten
            aller Vertriebsmitarbeiter:innen überwachen und den Bearbeitungsstand
            jedes eingereichten Neuvertrags über das Echtzeit-Statusreporting
            verfolgen. Das ONERI-Backoffice ermöglicht eine interne unabahängige
            Bearbeitung eingereichter Neuverträge bezüglich Qualitätssicherung,
            Genehmigung und Weiterleitung. So können Vertriebsergebnisse
            jederzeit vollständig überwacht und zur Vertriebssteuerung eigesetzt
            werden. Darüber hinaus erstellt ONERI im ABRECHNUNGSMODUL monatliche
            Gehalts- oder Provisionsabrechnungen für jeden Vertriebsmitarbeiter:innen.
            Die integrierte Abrechnung ermöglicht eine starke Verkürzung und
            Automatisierung der Betriebsabläufe im Innendienst
          </span>
        )}

        {isEnglish ? (
          ""
        ) : (
          <span className="section__two--text-p">
            ONERI ist die perfekte Lösung für Unternehmen, die ihre
            Vertriebsprozesse vereinfachen und sich im laufenden Betrieb auf
            qualitätsgesicherte Umsatzsteigerung fokussieren wollen. ONERI
            bringt Ihre Vertriebsaktivitäten auf ein höheres Level.
          </span>
        )}
      </div>
      <div onClick={handleToggle} className="showMoreButton">
        {isEnglish ? (
          <span>{isExpanded ? "Show less" : "Show more"}</span>
        ) : (
          <span>{isExpanded ? "Weniger anzeigen" : "Mehr anzeigen"}</span>
        )}
      </div>
      <div className="section__two--chart">
        {isEnglish ? (
          <img
            src={frontOfficeChartEn}
            alt=""
            className="section__two--chart-img"
          />
        ) : (
          <img
            src={frontOfficeChart}
            alt=""
            className="section__two--chart-img"
          />
        )}
      </div>
    </div>
  );
};

export default WebPlatform;
