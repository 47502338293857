import PotentialImage from "../../assets/potential_image.png";
import MeinePotentialImage from "../../assets/meine_potential_image.png";
import SalesTrackerImage from "../../assets/website_mockupNew1.png";
import TwoImageSectionTwo from '../../assets/New3.png';
import TwoImageSectionThree from '../../assets/3.png'
import SalesTrackerStatusImage from "../../assets/4.png";
import SalesTrackerStatisticImage from "../../assets/5.png";
import SalesTrackerOrderEntryImage from "../../assets/6.png";
import ImportImage from "../../assets/7.png";
import SalesTrackerPotentialLeadImage from "../../assets/8.png";



import LowPotentialImage from "../../assets/potential_image_11zon.png";
import LowMeinePotentialImage from "../../assets/meine_potential_image_11zon.png";
import LowSalesTrackerImage from "../../assets/Low1.png";
import LowDuplicateLeadImage from "../../assets/Low2.png";
import LowLeadAndPotentialImage from "../../assets/Low3.png";
import LowSalesTrackerStatusImage from "../../assets/Low4.png";
import LowSalesTrackerStatisticImage from "../../assets/Low5.png";
import LowSalesTrackerOrderEntryImage from "../../assets/Low6.png";
import LowImportImage from "../../assets/Low7.png";
import LowSalesTrackerPotentialLeadImage from "../../assets/Low8.png";

import CheckIcon from "../../assets/check_icon.svg";
import PhoneIcon from "../../assets/phone_icon.svg";
import RoationIcon from "../../assets/roation_icon.svg";
import BoxIcon from "../../assets/box_icon.svg";
import TagIcon from "../../assets/tag_icon.svg";
import WriteIcon from "../../assets/write_icon.svg";
import ImportIcon from "../../assets/import_icon.svg";
import BagIcon from "../../assets/bag_icon.svg";



export const cardData = {
  cardTitle: "Transparent lead management in real time:",
  card: `Keep track of every lead! The Sales Tracker gives you a comprehensive, real-time overview that helps managers and employees track progress, prioritize follow-ups, and make informed decisions quickly.`,
  cardTitleDe: "Transparente Lead-Verwaltung in Echtzeit:",
  cardDe: `Behalten Sie jeden Lead im Blick! Der Sales Tracker bietet Ihnen eine umfassende Echtzeit-Übersicht, mit der Manager und Mitarbeiter Fortschritte verfolgen, Nachfassaktionen priorisieren und schnell fundierte Entscheidungen treffen können.`, 
}
export const imageData = {
    lowImage: LowSalesTrackerImage,   
    largerImage: SalesTrackerImage
  }
  
export const contentData = [
  {
    titleImg: CheckIcon,
    title: 'Real-time processing of leads',
    titleDe: 'Echtzeitverarbeitung der Leads',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Transparent lead management in real time:",
    card: `Keep track of every lead! The Sales Tracker gives you a comprehensive, real-time overview that helps managers and employees track progress, prioritize follow-ups, and make informed decisions quickly.`,
    cardTitleDe: "Transparente Lead-Verwaltung in Echtzeit:",
    cardDe: `Behalten Sie jeden Lead im Blick! Der Sales Tracker bietet Ihnen eine umfassende Echtzeit-Übersicht, mit der Manager und Mitarbeiter Fortschritte verfolgen, Nachfassaktionen priorisieren und schnell fundierte Entscheidungen treffen können.`,
    lowImage: LowSalesTrackerImage,
    largerImage: SalesTrackerImage,
    reverse: false,
    singleLeft: true,
    singleRight: false,
    bulletPoints:{
      point1: "Overview",
      point2: "Prioritization",
      point3: "Decision-making power",
      point1De: "Übersicht",
      point2De: "Priorisierung",
      point3De: "Entscheidungskraft"
    },
    mobileXY: {
      left: {x: 45, y: 175},
      middle: {x: 208, y: 60},
      right: {x: 45, y: 440}
    },
    webXY: {
      left: {x: -20, y: 120},
      middle: {x: 225, y: 77},
      right: {x: 800, y: 110}
    }
  },
  {
    titleImg: PhoneIcon,
    title: 'Updates on the go',
    titleDe: 'Updates unterwegs',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Mobile integration for maximum flexibility:",
    card: `Automate lead status updates based on your team's location. As soon as a field sales rep visits the customer's location, the lead is automatically updated. If desired, information can also be edited manually - completely flexible according to your needs.`,
    cardTitleDe: "Mobile Integration für maximale Flexibilität:",
    cardDe: `Automatisieren Sie Lead-Status-Updates basierend auf dem Standort Ihres Teams. Sobald ein Außendienstmitarbeiter den Kundenstandort besucht, wird der Lead automatisch aktualisiert. Falls gewünscht, können Informationen auch manuell bearbeitet werden – ganz flexibel nach Ihren Bedürfnissen.`,
    lowImage: LowDuplicateLeadImage,
    largerImage: TwoImageSectionTwo,
    middleImage: PotentialImage,
    lowMiddleImage: LowPotentialImage,
    singleLeft: false,
    singleRight: true,
    reverse: true,
    bulletPoints:{
      point1: "Automation",
      point2: "Flexibility",
      point3: "Efficiency",
      point1De: "Automatisierung",
      point2De: "Flexibilität",
      point3De: "Effizienz"
    },
    mobileXY: {
      left: {x: 45, y: 855},
      middle: {x: 208, y: 670},
      right: {x: 45, y: 1130}
    },
    webXY: {
      left: {x: 800, y: 600},
      middle: {x: 225, y: 277},
      right: {x: -20, y: 600}
    }
  },
  {
    titleImg: RoationIcon,
    title: 'Location Limitation',
    titleDe: 'Standortbegrenzung',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Geofencing for intelligent lead updates:",
    card: `Each contract then undergoes a thorough review by the provider's back office team. 🛡 Here we ensure that everything is correct, complete and compliant. The goal? To ensure the highest quality and a smooth experience for customers and agents.`,
    cardTitleDe: "Geofencing für intelligente Lead-Updates:",
    cardDe: `Jeder Vertrag macht anschließend eine gründliche Überprüfung durch das Backoffice-Team des Anbieters. 🛡 Hier stellen wir sicher, dass alles korrekt, vollständig und regelkonform ist. Das Ziel? Höchste Qualität zu gewährleisten und ein reibungsloses Erlebnis für Kunden und Agenten sicherzustellen.`,
    lowImage: LowLeadAndPotentialImage,
    largerImage: TwoImageSectionThree,
    middleImage: MeinePotentialImage,
    lowMiddleImage: LowMeinePotentialImage,
    singleLeft: true,
    singleRight: false,
    reverse: false,
    bulletPoints:{
      point1: "Geofencing",
      point2: "Quality",
      point3: "Safety",
      point1De: "Geofencing",
      point2De: "Qualität",
      point3De: "Sicherheit"
    },
    mobileXY: {
      left: {x: 45, y: 1590},
      middle: {x: 208, y: 1330},
      right: {x: 45, y: 1870}
    },
    webXY: {
      left: {x: -20, y: 1100},
      middle: {x: 225, y: 477},
      right: {x: 800, y: 1100}
    }
  },
  {
    titleImg: BoxIcon,
    title: 'Effortless management',
    titleDe: 'Müheloses Management',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Advanced lead management for the back office:",
    card: `Sales Tracker is a complete solution for your lead management. Import lead lists, assign them to the right agents and create custom lead statuses to precisely segment and track progress.`,
    cardTitleDe: "Erweiterte Lead-Verwaltung für das Backoffice:",
    cardDe: `Der Sales Tracker ist eine vollständige Lösung für Ihr Lead-Management. Importieren Sie Lead-Listen, weisen Sie sie den passenden Mitarbeitern zu und erstellen Sie benutzerdefinierte Lead-Status, um den Fortschritt präzise zu segmentieren und zu verfolgen.`,
    lowImage: LowSalesTrackerStatusImage,
    largerImage: SalesTrackerStatusImage,
    singleLeft: false,
    singleRight: true,
    reverse: true,
    bulletPoints:{
      point1: "Organization",
      point2: "Precision",
      point3: "Segmentation",
      point1De: "Organisation",
      point2De: "Präzision",
      point3De: "Segmentierung"
    },
    mobileXY: {
      left: {x: 45, y: 2300},
      middle: {x: 208, y: 1970},
      right: {x: 45, y: 2570}
    },
    webXY: {
      left: {x: 800, y: 1630},
      middle: {x: 225, y: 677},
      right: {x: -20, y: 1630}
    }
  },
  {
    titleImg: TagIcon,
    title: 'Lead Statuses',
    titleDe: 'Lead-Status',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Individually customizable lead statuses:",
    card: `Bespoke statuses keep you in control. Back office staff can create labels such as "follow-up required", "quote sent" or "sale closed" so your sales team always knows the current status and can optimally manage leads.`,
    cardTitleDe: "Individuell anpassbare Lead-Status:",
    cardDe: `Mit maßgeschneiderten Status behalten Sie die Kontrolle. Backoffice-Mitarbeiter können Labels wie "Nachverfolgung erforderlich", "Angebot gesendet" oder "Verkauf abgeschlossen" anlegen, damit Ihr Vertriebsteam immer den aktuellen Stand kennt und Leads optimal betreuen kann.`,
    lowImage: LowSalesTrackerStatisticImage,
    largerImage: SalesTrackerStatisticImage,
    reverse: false,
    singleLeft: true,
    singleRight: false,
    bulletPoints:{
      point1: "Control",
      point2: "Transparency",
      point3: "Productivity",
      point1De: "Kontrolle",
      point2De: "Transparenz",
      point3De: "Produktivität"
    },
    mobileXY: {
      left: {x: 45, y: 2990},
      middle: {x: 208, y: 2590},
      right: {x: 45, y: 3270}
    },
    webXY: {
      left: {x: -20, y: 2130},
      middle: {x: 225, y: 877},
      right: {x: 800, y: 2115}
    }
  },
  {
    titleImg: WriteIcon,
    title: 'From Lead to Contract',
    titleDe: 'Vom Lead zum Vertrag',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Efficient contract creation:",
    card: `Easily convert leads into contracts – directly from the lead overview. Save time and avoid switching between different systems. One click, one contract – it can be that easy!`,
    cardTitleDe: "Effiziente Vertragserstellung:",
    cardDe: `Verwandeln Sie Leads mühelos in Verträge – direkt aus der Lead-Übersicht heraus. Sparen Sie Zeit und vermeiden Sie den Wechsel zwischen verschiedenen Systemen. Ein Klick, ein Vertrag – so einfach kann es sein!`,
    lowImage: LowSalesTrackerOrderEntryImage,
    largerImage: SalesTrackerOrderEntryImage,
    reverse: true,
    singleLeft: false,
    singleRight: true,
    bulletPoints:{
      point1: "Simplification",
      point2: "Time-saving",
      point3: "Integration",
      point1De: "Vereinfachung",
      point2De: "Zeitsparnis",
      point3De: "Integration"
    },
    mobileXY: {
      left: {x: 45, y: 3740},
      middle: {x: 208, y: 3260},
      right: {x: 45, y: 4000}
    },
    webXY: {
      left: {x: 800, y: 2630},
      middle: {x: 225, y: 1077},
      right: {x: -20, y: 2610}
    }
  },
  {
    titleImg: ImportIcon,
    title: 'Data Transfer',
    titleDe: 'Datenübernahme',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Bulk import for new opportunities:",
    card: `New customer data? No problem! The Sales Tracker allows you to quickly import lead lists and automatically create lead profiles that can be processed immediately by your team.`,
    cardTitleDe: "Bulk-Import für neue Chancen:",
    cardDe: `Neue Kundendaten? Kein Problem! Der Sales Tracker ermöglicht den schnellen Import von Lead-Listen und die automatische Erstellung von Lead-Profilen, die sofort von Ihrem Team bearbeitet werden können.`,
    lowImage: LowImportImage,
    largerImage: ImportImage,
    reverse: false,
    singleLeft: true,
    singleRight: false,
    bulletPoints:{
      point1: "Speed",
      point2: "Automation",
      point3: "User-friendliness",
      point1De: "Schnelligkeit",
      point2De: "Automatisierung",
      point3De: "Bedienkomfort"
    },
    mobileXY: {
      left: {x: 45, y: 4430},
      middle: {x: 208, y: 3890},
      right: {x: 45, y: 4700}
    },
    webXY: {
      left: {x: -20, y: 3130},
      middle: {x: 225, y: 1277},
      right: {x: 800, y: 3120}
    }
  },
  {
    titleImg: BagIcon,
    title: 'Pre-sales at your fingertip',
    titleDe: 'Vorverkauf auf Knopfdruck',
    secondTitle: "",
    secondTitleDe: "",
    secondTitleImg: "",
    cardTitle: "Your all-in-one solution for sales optimization:",
    card: `Sales Tracker does much more than just lead tracking. It optimizes your entire sales process – from lead assignment to real-time updates to contract management – ​​and increases the efficiency of your team.`,
    cardTitleDe: "Ihre Alles-in-einem-Lösung für Vertriebsoptimierung:",
    cardDe: `Der Verkaufstracker bietet weit mehr als nur Lead-Tracking. Er optimiert Ihren gesamten Vertriebsprozess – von der Lead-Zuweisung über Echtzeit-Updates bis hin zum Vertragsmanagement – und steigert so die Effizienz Ihres Teams.`,
    lowImage: LowSalesTrackerPotentialLeadImage,
    largerImage: SalesTrackerPotentialLeadImage,
    reverse: true,
    singleLeft: false,
    singleRight: true,
    bulletPoints:{
      point1: "Optimization",
      point2: "Performance",
      point3: "Completeness",
      point1De: "Optimierung",
      point2De: "Leistungsfähigkeit",
      point3De: "Vollständigkeit"
    },
    mobileXY: {
      left: {x: 45, y: 5130},
      middle: {x: 208, y: 4515},
      right: {x: 45, y: 5405}
    },
    webXY: {
      left: {x: 800, y: 3630},
      middle: {x: 225, y: 1477},
      right: {x: -20, y: 3625}
    }
  },
];
