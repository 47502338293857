import React from "react";
import "./index.css";

const SalesTrackerCard = ({ isEnglish, section }) => {

  const bulletPoints = [
    isEnglish ? section?.bulletPoints?.point1 : section?.bulletPoints?.point1De,
    isEnglish ? section?.bulletPoints?.point2 : section?.bulletPoints?.point2De,
    isEnglish ? section?.bulletPoints?.point3 : section?.bulletPoints?.point3De,
  ].filter(Boolean);

  return (
    <div className="sales-tracker-section-content-left-card">
      <div className="sales-tracker-section-content-left-card-box">
        <div className="sales-tracker-section-content-left-card-box-inside">
          <p className="sales-tracker-section-content-left-card-box-inside-title">
            {isEnglish ? section.cardTitle : section.cardTitleDe}
          </p>
          <p className="sales-tracker-section-content-left-card-box-inside-text">
            {isEnglish ? section.card : section.cardDe}
          </p>

          {bulletPoints.length > 0 && (
            <ul className="bulletPoints-content">
              {bulletPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesTrackerCard;
